<template>
  <q-page :style-fn="getAreaDimension">
    <div
      class="row q-mt-sm q-mx-auto max-container-width"
      :style="`min-height: ${areaHeight ? `${areaHeight}px` : '75vh'}`"
    >
      <div class="col bg-white border-radius-4 border-box q-py-sm q-px-md">
        <ScheduleCalendar />
      </div>
    </div>
  </q-page>
</template>

<script>
import ScheduleCalendar from '../components/ScheduleCalendar'

export default {
  components: {
    ScheduleCalendar
  },

  data () {
    return { areaHeight: 0 }
  },

  mounted () {
    const vm = this
    const isAdmin = vm.$store.getters.isAdmin
    if (!isAdmin) return vm.$router.go(-1)
  },

  methods: {
    getAreaDimension (offset, height) {
      this.areaHeight = height - offset - 16
    }
  }
}
</script>
